var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "LBL0002116" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.editInfo &&
                          _vm.preStartupCheck.sopPrestartupCheckId &&
                          !_vm.disabled,
                        expression:
                          "editInfo&&preStartupCheck.sopPrestartupCheckId&&!disabled",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isSave,
                      url: _vm.saveUrl,
                      param: _vm.preStartupCheck,
                      mappingType: _vm.mappingType,
                      label: "저장",
                      icon: "check",
                    },
                    on: {
                      beforeAction: _vm.savePreStartupEquipment,
                      btnCallback: _vm.saveCallback,
                    },
                  }),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.editInfo &&
                          _vm.preStartupCheck.sopPrestartupCheckId &&
                          !_vm.disabled,
                        expression:
                          "editInfo&&preStartupCheck.sopPrestartupCheckId&&!disabled",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isComplete,
                      url: _vm.completeUrl,
                      param: _vm.preStartupEquipment,
                      mappingType: "PUT",
                      label: "LBLCOMPLETE",
                      icon: "check",
                    },
                    on: {
                      beforeAction: _vm.completePreStartupEquipment,
                      btnCallback: _vm.completeCallback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-2 col-lg-2" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editInfo,
                    disabled: true,
                    label: "LBL0000633",
                    name: "equipmentTypeName",
                  },
                  model: {
                    value: _vm.preStartupEquipment.equipmentTypeName,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.preStartupEquipment,
                        "equipmentTypeName",
                        $$v
                      )
                    },
                    expression: "preStartupEquipment.equipmentTypeName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-multi-select", {
                  attrs: {
                    editable: _vm.editInfo,
                    disabled: _vm.disabled,
                    isObject: true,
                    disabledItems: _vm.disabledItems,
                    codeGroupCd: "PSR_CHECK_TYPE_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    valueText: "psrCheckTypeName",
                    valueKey: "psrCheckTypeCd",
                    label: "LBL0002122",
                    name: "checkTypes",
                  },
                  on: { datachange: _vm.changeCheckType },
                  model: {
                    value: _vm.preStartupEquipment.checkTypes,
                    callback: function ($$v) {
                      _vm.$set(_vm.preStartupEquipment, "checkTypes", $$v)
                    },
                    expression: "preStartupEquipment.checkTypes",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-2 col-lg-2" },
              [
                _c("c-field", {
                  attrs: {
                    editable: _vm.editInfo,
                    disabled: _vm.disabled,
                    type: "user",
                    label: "LBL0002117",
                    name: "checkUserId",
                  },
                  model: {
                    value: _vm.preStartupEquipment.checkUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.preStartupEquipment, "checkUserId", $$v)
                    },
                    expression: "preStartupEquipment.checkUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-2 col-lg-2" },
              [
                _c("c-datepicker", {
                  attrs: {
                    editable: _vm.editInfo,
                    disabled: _vm.disabled,
                    label: "LBL0002118",
                    name: "checkScheduleDate",
                  },
                  model: {
                    value: _vm.preStartupEquipment.checkScheduleDate,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.preStartupEquipment,
                        "checkScheduleDate",
                        $$v
                      )
                    },
                    expression: "preStartupEquipment.checkScheduleDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-2 col-lg-2" },
              [
                _c("c-datepicker", {
                  attrs: {
                    editable: _vm.editInfo,
                    disabled: _vm.disabled,
                    label: "LBL0002123",
                    name: "checkDate",
                  },
                  model: {
                    value: _vm.preStartupEquipment.checkDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.preStartupEquipment, "checkDate", $$v)
                    },
                    expression: "preStartupEquipment.checkDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editInfo,
                    disabled: _vm.disabled,
                    label: "LBL0002120",
                    name: "checkResultTotal",
                  },
                  model: {
                    value: _vm.preStartupEquipment.checkResultTotal,
                    callback: function ($$v) {
                      _vm.$set(_vm.preStartupEquipment, "checkResultTotal", $$v)
                    },
                    expression: "preStartupEquipment.checkResultTotal",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "LBL0002121",
          columns: _vm.grid.columns,
          gridHeight: _vm.gridHeight,
          data: _vm.preStartupEquipment.checkItems,
          merge: _vm.grid.merge,
          filtering: false,
          columnSetting: false,
          editable: _vm.editInfo && !_vm.disabled,
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name === "customCol"
                  ? [
                      _c(_vm.imprComponent, {
                        tag: "component",
                        attrs: {
                          col: col,
                          props: props,
                          inputEditable: _vm.editInfo && !_vm.disabled,
                          isImmShow: true,
                          requestContentsCols: _vm.requestContentsCols,
                          tableKey: "sopPrestartupEquipmentHasCheckItemId",
                          ibmTaskTypeCd: "ITT0000030",
                          ibmTaskUnderTypeCd: "ITTU000050",
                        },
                        on: { imprChange: _vm.imprChange },
                      }),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }